import React, { Suspense } from 'react'

function lazyLoad (WrappedComponent) {
  return class extends React.Component {
    render () {
      const self = this
      return (
        <Suspense fallback={<div />}>
          <WrappedComponent {...self.props} />
        </Suspense>
      )
    }
  }
}

export {
  lazyLoad
}
