import React from 'react'
import PropTypes from 'prop-types'
import './_modal.scss'
import closeIcon from '../../../styles/assets/images/ic-close.svg'

class ModalComponent extends React.Component {
  constructor (props) {
    super(props)
    if (props.isOpen) {
      document.body.style.overflow = 'hidden'
    }
  }

  componentDidUpdate () {
    if (this.props.isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
    if (this.props.shouldCloseOnOverlayClick) {
      document.addEventListener('mousedown', this.handleClickOutside)
    }
    if (this.props.shouldCloseOnEsc) {
      document.addEventListener('keydown', this.handleEscKeyPress, false)
    }
  }

  componentWillUnmount () {
    if (this.props.shouldCloseOnOverlayClick) {
      document.removeEventListener('mousedown', this.handleClickOutside)
    }
    if (this.props.shouldCloseOnEsc) {
      document.removeEventListener('keydown', this.handleEscKeyPress, false)
    }
  }

  handleClickOutside = (event) => {
    if (this.contentRef && !this.contentRef.contains(event.target) &&
      this.props.isOpen) {
      this.handleModalClose()
    }
  }

  handleEscKeyPress = (event) => {
    if (event.keyCode === 27 && this.props.isOpen) {
      this.handleModalClose()
    }
  }

  setContentRef = (node) => {
    this.contentRef = node
  }

  handleModalClose = () => {
    this.props.handleOnCloseModal()
  }

  render () {
    if (!this.props.isOpen) {
      return null
    }
    let contentClassName = 'modal-content '
    if (this.props.contentClassName) {
      contentClassName += this.props.contentClassName
    }
    return (
      <div className='modal-overlay'>
        <div className={contentClassName} ref={this.setContentRef}>
          {!this.props.hideCloseSection &&
            <div className='close-icon-container'>
              <img
                alt='close-modal'
                src={closeIcon} className='close-icon'
                onClick={this.handleModalClose}
              />
            </div>}
          {this.props.children}
        </div>
      </div>
    )
  }
}

ModalComponent.propTypes = {
  handleOnCloseModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  shouldCloseOnOverlayClick: PropTypes.bool,
  shouldCloseOnEsc: PropTypes.bool,
  hideCloseSection: PropTypes.bool,
  contentClassName: PropTypes.string
}

export default ModalComponent
