import React, { Component } from 'react'
import NavBarView from './nav-bar.view'
import { withRouter } from 'react-router-dom'
import userService from '../../../services/user.service'
import { CoreStateContext } from '../../../context/core'
import { setCommonModal, deleteFromCommonModal, addSchoolData } from '../../../context/core/core.actions'
import { MODAL_TYPES } from '../../../constants/core-modal/modal-types'
import { getFileUrlFromBuffer } from '../../../utils/common'

class NavBarComponent extends Component {
  static contextType = CoreStateContext
  state = {
    schoolLogoUrl: '',
    areRightDetailsVisible: false
  }
  componentDidMount () {
    const commonState = this.context.commonState || {}
    if (commonState.schoolData && commonState.schoolData.selectedSchool) {
      this.processSchoolsList(commonState.schoolData.schoolsList)
    } else {
      this.fetchSchoolsList()
    }
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleOutsideClick)
  }

  handleOutsideClick = (e) => {
    if (this.state.areRightDetailsVisible) {
      const elem = document.getElementById('right-action-block')
      if (elem && elem.contains(e.target)) {
        return
      }
      this.setState({
        areRightDetailsVisible: false
      }, () => {
        document.removeEventListener('click', this.handleOutsideClick)
      })
    }
  }

  fetchSchoolsList = () => {
    userService.fetchSchools().then((res) => {
      this.processSchoolsList(res.schools)
    }, (err) => {

    })
  }

  fetchSchoolProfile = (selectedSchool) => {
    userService.getSchoolProfile(selectedSchool.id).then((res) => {
      if (res.data.logo) {
        this.setState({
          schoolLogoUrl: getFileUrlFromBuffer(res.data.logo)
        })
      }
    }, (err) => {

    })
  }

  processSchoolsList = (schoolsList) => {
    let localSchool
    let isSchoolFromParam
    if (this.props.location.pathname.startsWith('/schools')) {
      let location = this.props.location.pathname
      location = location.split('/')
      localSchool = { id: parseInt(location[2]) }
      isSchoolFromParam = true
    } else {
      localSchool = userService.getSelectedSchool()
    }
    const { filteredSchool, selectedSchool, acadYears, selectedAcadYear } = userService.processSchoolAcadSelection(localSchool, schoolsList)
    if (isSchoolFromParam && !filteredSchool) {
      this.handleLogout()
      return
    }
    this.fetchSchoolProfile(selectedSchool)
    this.context.dispatchCoreAction(addSchoolData({
      selectedSchool,
      acadYears,
      schoolsList: schoolsList,
      selectedAcadYear
    }))
    this.setState({
      shouldRender: true
    })
  }

  handleLogout = () => {
    userService.logout()
    this.props.history.push('/auth/login')
  }

  handleRedirect = () => {
    if (userService.isLoggedIn()) {
      userService.logout()
      this.props.history.push('/auth/login')
    } else {
      this.props.history.push('/auth/login')
    }
  }

  handleShowActions = () => {
    // const modalData = {
    //   modalId: 'navActionsModal',
    //   type: MODAL_TYPES.NAV_ACTIONS_LIST
    // }
    // this.context.dispatchCoreAction(setCommonModal(modalData))
    this.setState({
      areRightDetailsVisible: true
    }, () => {
      document.addEventListener('click', this.handleOutsideClick)
    })
  }

  handleDeleteModal = () => {
    this.context.dispatchCoreAction(deleteFromCommonModal({modalId: 'navActionsModal'}))
  }

  handleShowSelectSchoolModal = () => {
    const modalData = {
      modalId: 'selectSchoolModal',
      type: MODAL_TYPES.SCHOOL_SELECTION,
      hideCloseSection: true,
      modalProps: {
        selectedSchool: this.context.commonState.schoolData.selectedSchool,
        schoolsList: this.context.commonState.schoolData.schoolsList,
        onSchoolSelect: this.handleSchoolSelect
      }
    }
    this.context.dispatchCoreAction(setCommonModal(modalData))
  }

  handleSchoolSelect = (selectedSchool = null) => {
    if (selectedSchool) {
      const { acadYears, schoolsList, selectedAcadYear } = this.context.commonState.schoolData
      this.context.dispatchCoreAction(addSchoolData({
        selectedSchool,
        acadYears,
        schoolsList: schoolsList,
        selectedAcadYear
      }))
      this.props.history.push(`/schools/${selectedSchool.id}`)
    }
    this.context.dispatchCoreAction(deleteFromCommonModal({modalId: 'selectSchoolModal'}))
  }

  render () {
    const commonState = this.context.commonState || {}
    const schoolData = commonState.schoolData || {}
    // if (!schoolData.selectedSchool) {
    //   return null
    // }
    return (
      <NavBarView onHandleRedirect={this.handleRedirect}
        isLoggedIn={userService.isLoggedIn()}
        onShowActions={this.handleShowActions}
        handleDeleteModal={this.handleDeleteModal}
        selectedSchool={schoolData.selectedSchool}
        userDetails={userService.getUserDetails()}
        onShowSelectSchoolModal={this.handleShowSelectSchoolModal}
        schoolLogoUrl={this.state.schoolLogoUrl}
        areRightDetailsVisible={this.state.areRightDetailsVisible}
        onLogout={this.handleLogout}
      />
    )
  }
}

export default withRouter(NavBarComponent)
