import React from 'react'
import { lazyLoad } from '../../hoc/lazyload.hoc'
import { MODAL_TYPES } from './modal-types'

const NavActionsComponent = React.lazy(() =>
  import(/* webpackChunkName: "nav-actions" */ '../../components/common/modal-components/nav-actions')
)
const SchoolSelectionComponent = React.lazy(() =>
  import(/* webpackChunkName: "school-selection" */ '../../components/common/modal-components/school-selection')
)

const NavActionsLazyComponent = lazyLoad(NavActionsComponent)
const SchoolSelectionLazyComponent = lazyLoad(SchoolSelectionComponent)

const MODAL_TYPE_COMPONENT_MAPPING = {
  [MODAL_TYPES.NAV_ACTIONS_LIST]: NavActionsLazyComponent,
  [MODAL_TYPES.SCHOOL_SELECTION]: SchoolSelectionLazyComponent
}

export { MODAL_TYPE_COMPONENT_MAPPING }
