import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import './index.scss'
import App from './app'
import * as serviceWorker from './serviceWorker'
import { useCoreStateProvider } from './context/core'
const AppWithCommonState = useCoreStateProvider(App)

ReactDOM.render(
  <Router basename='/'>
    { AppWithCommonState }
  </Router>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
