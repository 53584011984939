import { Cookies } from 'react-cookie'
import httpService from './http.service'
import localStorageService from './storage/localStorage.service'
import idbService from './storage/idb.service'

const AUTH_CONSTANTS = {
  USER_INFO: 'user',
  ACF: 'acf',
  TOKEN: 'token',
  AUTH_TOKEN: 'auth_token',
  SELECTED_SCHOOL: 'selected_school',
  SELECTED_SCHOOL_ID: 'selected_school_id',
  SELECTED_ACAD_YEAR: 'academic_year_id',
  SELECTED_ACADEMIC_CODE: 'academic_code',
  SATELLIZER_TOKEN: 'satellizer_token',
  SCHOOL_LIST: 'schoolList'
}

const API_URLS = {
  LOGIN: '/auth/login',
  FORGOT_PASSWORD: '/auth/forgotPassword',
  RESET_PASSWORD: '/auth/resetPassword'
}

class UserService {
  constructor () {
    this.cookies = new Cookies()
  }

  isLoggedIn () {
    return !!(localStorageService.getData(AUTH_CONSTANTS.AUTH_TOKEN))
  }

  isOnLocal () {
    return window.location.host.indexOf('localhost') > -1
  }

  login (userDetails) {
    return httpService.post(API_URLS.LOGIN, userDetails)
  }

  forgotPassword (mobileDetails) {
    return httpService.post(API_URLS.FORGOT_PASSWORD, mobileDetails)
  }

  resetPassword (resetDetails) {
    return httpService.post(API_URLS.RESET_PASSWORD, resetDetails)
  }

  handleSuccessfullLogin (response) {
    localStorageService.setData(AUTH_CONSTANTS.USER_INFO, response.user)
    localStorageService.setData(AUTH_CONSTANTS.ACF, response.user.acf)
    localStorageService.setData(AUTH_CONSTANTS.AUTH_TOKEN, response.token)
    localStorageService.setData(AUTH_CONSTANTS.SATELLIZER_TOKEN, response.token)

    return response
  }

  logout () {
    localStorageService.removeItem(AUTH_CONSTANTS.USER_INFO, null)
    localStorageService.removeItem(AUTH_CONSTANTS.ACF, null)
    localStorageService.removeItem(AUTH_CONSTANTS.AUTH_TOKEN, null)
    localStorageService.removeItem(AUTH_CONSTANTS.SATELLIZER_TOKEN, null)
    idbService.clearData()
  }

  getUserDetails = () => {
    return localStorageService.getData(AUTH_CONSTANTS.USER_INFO)
  }

  fetchSchools () {
    return httpService.get('/api/schools')
  }

  processSchoolAcadSelection (localSchool, schoolsList) {
    let selectedSchool, acadYears, selectedAcadYear, filteredSchool
    if (localSchool) {
      filteredSchool = schoolsList.find(school => school.id === (localSchool.schoolid || localSchool.id))
      selectedSchool = filteredSchool
      if (filteredSchool) {
        acadYears = filteredSchool.acadyears
        selectedAcadYear = this.getLatestAcadYear(filteredSchool.acadyears)
      } else {
        selectedSchool = schoolsList[0]
        acadYears = schoolsList[0].acadyears
        selectedAcadYear = this.getLatestAcadYear(schoolsList[0].acadyears)
      }
    } else {
      selectedSchool = schoolsList[0]
      acadYears = schoolsList[0].acadyears
      selectedAcadYear = this.getLatestAcadYear(schoolsList[0].acadyears)
    }
    this.setSelectedSchool(selectedSchool)
    this.setSelectedAcadYear(selectedAcadYear)
    return {
      filteredSchool,
      selectedSchool,
      acadYears,
      selectedAcadYear
    }
  }

  getLatestAcadYear = (acadYears) => {
    return acadYears.sort((a, b) => { return b.id - a.id })[0]
  }

  setSelectedSchool (school) {
    localStorageService.setData(AUTH_CONSTANTS.SELECTED_SCHOOL, school)
    localStorageService.setData(AUTH_CONSTANTS.SELECTED_SCHOOL_ID, school.id)
  }

  setSelectedAcadYear (acadYear = {}) {
    localStorageService.setData(AUTH_CONSTANTS.SELECTED_ACAD_YEAR, acadYear.id)
    localStorageService.setData(AUTH_CONSTANTS.SELECTED_ACADEMIC_CODE, acadYear.code)
  }

  getSelectedSchool () {
    return localStorageService.getData(AUTH_CONSTANTS.SELECTED_SCHOOL)
  }

  getSchoolProfile (schoolId) {
    return httpService.get(`/api/gtw/v1/${schoolId}/school/schoolprofile`)
  }
}

const userService = new UserService()
export default userService
