import axios from 'axios'
import localStorageService from './storage/localStorage.service'
import userService from './user.service'
// const API_PREFIX = 'http://pydevscherp.leadschool.in:3020'
// const API_PREFIX = 'https://play-nucleus.leadschool.in'

// const API_PREFIX_MAP = {
//   dev: 'http://pydevscherp.leadschool.in',
//   qa: 'http://pydevscherp.leadschool.in',
//   uat: 'http://pydevscherp.leadschool.in',
//   staging: 'http://pydevscherp.leadschool.in',
//   production: 'http://pydevscherp.leadschool.in'
// }

// const APP_CONST = {
//   TOKEN: 'token',
//   USER_DETAILS: 'userDetails',
//   TOAST_IMEOUT: 3500,
//   aTOKEN: 'atoken',
//   idTOKEN: 'idtoken'
// }

class HttpService {
  constructor () {
    // if (process && process.env && process.env.NODE_ENV) {
    //   this.apiGateway = API_PREFIX_MAP[process.env.NODE_ENV.toLowerCase()] || API_PREFIX;
    // } else {
    //   this.apiGateway = API_PREFIX;
    // }
    this.apiGateway = ''
  }

  init (historyChangeCallback) {
    this.onChangeHistory = historyChangeCallback
  }

  setDispatch (dispatch) {
    this.dispatch = dispatch
  }

  httpHeaders () {
    const authToken = localStorageService.getData('auth_token')
    const acadYear = 4
    return {
      // "api-version": "v1.8",
      // "client-auth-method": "v1",
      // "client-id": "web-client/1.0",
      // "secret-key": "SdeF21dn1ll23ms1AEcn223Ln039kds",
      Accept: 'application/json, text/javascript, */*; q=0.01',
      // "device-id": "",
      // "install-id": "",
      'Content-Type': 'application/json',
      Authorization: authToken,
      academicyearid: acadYear || 4,
      source: 'webapp'
      // "UserName": ''
    }
  }

  get (url, params, isBufferresponse = false, additionalHeaders = {}, requireHeaders = false) {
    const reqConfig = {
      method: 'get',
      url,
      headers: Object.assign({}, this.httpHeaders(), additionalHeaders),
      params: params
    }

    if (isBufferresponse) {
      reqConfig.responseType = 'arraybuffer'
    }
    this.requestConfig = reqConfig
    return this.processRequest(reqConfig, requireHeaders)
  }

  post (url, data = {}, params = {}, isBufferresponse = false, additionalHeaders = {}) {
    const reqConfig = {
      method: 'post',
      url,
      headers: Object.assign({}, this.httpHeaders(), additionalHeaders),
      params: params,
      data: data
    }
    if (isBufferresponse) {
      reqConfig.responseType = 'arraybuffer'
    }
    if (!url.includes('login')) {
      this.requestConfig = reqConfig
    }
    return this.processRequest(reqConfig)
  }

  bufferDownload (url, data = {}, params = {}) {
    const reqConfig = {
      method: 'post',
      url,
      headers: this.httpHeaders(),
      params: params,
      data: data,
      responseType: 'arraybuffer'
    }
    if (!url.includes('login')) {
      this.requestConfig = reqConfig
    }
    return this.processRequest(reqConfig)
  }

  patch (url, data = {}, params = {}, additionalHeaders = {}) {
    const reqConfig = {
      method: 'patch',
      url,
      headers: [this.httpHeaders(), additionalHeaders],
      params: params,
      data: data
    }
    if (!url.includes('login')) {
      this.requestConfig = reqConfig
    }
    return this.processRequest(reqConfig)
  }

  put (url, data = {}, params = {}, additionalHeaders = {}) {
    const reqConfig = {
      method: 'put',
      url,
      headers: this.httpHeaders(),
      params: params,
      data: data
    }
    this.requestConfig = reqConfig
    return this.processRequest(reqConfig)
  }

  delete (url, data = {}, params = {}) {
    const reqConfig = {
      method: 'delete',
      url,
      headers: this.httpHeaders(),
      params: params,
      data: data
    }
    this.requestConfig = reqConfig
    return this.processRequest(reqConfig)
  }

  fileUpload (url, data = {}, params = {}, additionalHeaders = {}) {
    const headers = this.httpHeaders()
    headers['Content-Type'] = 'multipart/form-data'
    const reqConfig = {
      method: 'put',
      url,
      headers: Object.assign({}, this.httpHeaders(), additionalHeaders),
      params: params,
      data: data
    }
    if (!url.includes('login')) {
      this.requestConfig = reqConfig
    }
    return this.processRequest(reqConfig)
  }

  processRequest (requestConfig, requireHeaders = false) {
    const self = this
    const configCopy = Object.assign({}, requestConfig)
    configCopy.url = this.apiGateway + configCopy.url
    return axios(configCopy)
      .then(function (response) {
        if (
          response &&
          response.data &&
          response.data.code &&
          response.data.code.toString() !== '0'
        ) {
          return Promise.reject(response)
        }

        // if (response.statusText === "OK") return response.data;
        if (response && response.status && response.status >= 200 && response.status < 300 &&
          response.data && ((configCopy.responseType === 'arraybuffer') ||
          (response.data.length > 1024 || response.data.success || response.data.status))) {
          if (requireHeaders) {
            return {
              data: response.data,
              headers: response.headers
            }
          }
          return response.data
        }
        throw response
      })
      .catch(function (error) {
        if (error && error.response && (error.response.status === 401)) {
          self.unauthorisedError = error
          return self.handleUnauthorised(error.response.data)
        } else if (error.response && error.response.status >= 500) {
          return Promise.reject(error)
        } else if (error.message === 'Network Error') {
          return Promise.reject(error)
        } else {
          return Promise.reject(error)
        }
      })
  }

  handleUnauthorised = (error) => {
    userService.logout()
    this.onChangeHistory('/auth/login')
    return Promise.reject(error)
  }
}

const httpService = new HttpService()
export default httpService
