class LocalStorageService {
  constructor () {
    this._window = window
    if (this.isLocalStorageSupported()) {
      this._localStorage = this._window.localStorage
    } else {
      this._localStorage = {}
      this._localStorage.setItem = function (key, value) {
        this[key] = value
      }
      this._localStorage.getItem = function (key) {
        return this[key] ? null : this[key]
      }
      this._localStorage.removeItem = function (key) {
        delete this[key]
      }
    }
  }

  isLocalStorageSupported () {
    const testKey = 'test'; const storage = this._window.localStorage
    try {
      storage.setItem(testKey, 'abc')
      storage.removeItem(testKey)
      return true
    } catch (e) {
      return false
    }
  }

  setData (key, val) {
    const _self = this
    _self._localStorage && _self._localStorage.setItem(key, JSON.stringify(val))
  }

  getData (key) {
    const _self = this
    if (_self._localStorage && _self._localStorage.getItem(key)) {
      try {
        return JSON.parse(_self._localStorage.getItem(key))
      } catch (e) {
        return _self._localStorage.getItem(key)
      }
    }
    return null
  }

  removeItem (key) {
    const _self = this
    _self._localStorage && _self._localStorage.removeItem(key)
  }
}

const localStorageService = new LocalStorageService()
export default localStorageService
