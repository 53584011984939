const TYPES = {
  CORE_DIALOG: 'CORE_DIALOG',
  CORE_ERROR: 'CORE_ERROR',
  CORE_MODAL: 'CORE_MODAL',
  SCHOOL_DATA: 'SCHOOL_DATA',
  DELETE_CORE_MODAL: 'DELETE_CORE_MODAL'
}

const coreStateReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case TYPES.CORE_DIALOG:
      return {
        ...state,
        coreDialog: action.data || null
      }
    case TYPES.CORE_ERROR:
      return {
        ...state,
        coreError: action.data || null
      }
    case TYPES.CORE_MODAL:
      return {
        ...state,
        coreModalList: {
          ...state.coreModalList || {},
          [action.data.modalId]: action.data
        }
      }
    case TYPES.DELETE_CORE_MODAL:
      const coreModalList = state.coreModalList
      delete coreModalList[action.data.modalId]
      return {
        ...state,
        coreModalList
      }
    case TYPES.SCHOOL_DATA:
      return {
        ...state,
        schoolData: action.data || null
      }
    default:
      return state
  }
}

const coreReducer = ({ commonState }, action) => ({
  commonState: coreStateReducer(commonState, action)
})

export { coreReducer, TYPES }
