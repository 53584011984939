import { TYPES } from './'

const setCommonModal = (data) => {
  return {
    type: TYPES.CORE_MODAL,
    data
  }
}

const deleteFromCommonModal = (data) => {
  return {
    type: TYPES.DELETE_CORE_MODAL,
    data
  }
}

const showCoreDialog = (data) => {
  return {
    type: TYPES.CORE_DIALOG,
    data
  }
}

const showCoreError = (data) => {
  return {
    type: TYPES.CORE_ERROR,
    data
  }
}

const addSchoolData = (data) => {
  return {
    type: TYPES.SCHOOL_DATA,
    data
  }
}

export { showCoreDialog, showCoreError, setCommonModal, addSchoolData, deleteFromCommonModal }
