var indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
var dataBase = null;

class IdbService {
  startDb = (initialisedCallback) => {
    dataBase = indexedDB.open("leadschool", 1)
    dataBase.onupgradeneeded = function (e) {
      var active = dataBase.result;
      var objectDb = active.createObjectStore("so-app", {keyPath: 'endpoint' })
      objectDb.createIndex('api_responses', 'endpoint')
    }
    dataBase.onsuccess = function (e) {
      this.dbInitialised = true
      if (initialisedCallback) {
        initialisedCallback()
      }
    }
    dataBase.onerror = function (e) {
      this.dbInitialised = false
      if (initialisedCallback) {
        initialisedCallback()
      }
    }
  }
  addData = (data, key, val) => {
    let active = dataBase.result;
    let objectDb = active.transaction(["so-app"], "readwrite").objectStore("so-app")
    let dataCopy = Object.assign({}, data)
    dataCopy[key] = val
    objectDb.put(dataCopy)
  }

  getData = (key, successCallback, errorCallback) => {
    let active = dataBase.result;
    let objectDb = active.transaction(["so-app"], "readwrite").objectStore("so-app")
    var request = objectDb.get(key)
    request.onsuccess = function (e) {
      if (e.target.result && successCallback) {
        successCallback(e.target.result)
      }
      if (!e.target.result && errorCallback) {
        errorCallback({ err: 'Data not found' })
      }
    }
    request.onerror = function (e) {
      if (errorCallback) {
        errorCallback(e.target.result)
      }
    }
  }

  clearData = () => {
    const active = dataBase.result;
    const objectDb = active.transaction(["so-app"], "readwrite").objectStore("so-app")
    objectDb.clear()
  }
}

const idbService = new IdbService()
export default idbService
