import React, { Component } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import './_app.scss'
import NavBarComponent from './components/common/nav-bar'
import { lazyLoad } from './hoc/lazyload.hoc'
import userService from './services/user.service'
import idbService from './services/storage/idb.service'
import { CoreStateContext } from './context/core'
import { MODAL_TYPE_COMPONENT_MAPPING } from './constants/core-modal/type-component-mappings'
import ModalComponent from './components/common/modal'
import { deleteFromCommonModal } from './context/core/core.actions'

const AuthComponent = React.lazy(() =>
  import(/* webpackChunkName: "auth" */ './components/modules/auth')
)
const SchoolsComponent = React.lazy(() =>
  import(/* webpackChunkName: "schools" */ './components/modules/schools')
)

const AuthLazyComponent = lazyLoad(AuthComponent)
const SchoolsLazyComponent = lazyLoad(SchoolsComponent)

// Private route
const PrivateRoute = ({ component: Component, additionalProps, providerFunc, ...rest }) => {
  return (
    <Route
      {...rest} render={(props) => (
        userService.isLoggedIn()
          ? (providerFunc ? providerFunc(Component, additionalProps)
            : <Component {...props} {...additionalProps} />)
          : <Redirect to='/auth/login' />
      )}
    />
  )
}

// route to redirect if user is logged in
const PublicRoute = ({ component: Component, additionalProps, providerFunc, ...rest }) => {
  let redirecUrl = '/home'
  return (
    <Route
      {...rest} render={(props) => (
        userService.isLoggedIn()
          ? <Redirect to={redirecUrl} />
          : (providerFunc ? providerFunc(Component, additionalProps)
            : <Component {...props} {...additionalProps} />)
      )}
    />
  )
}

class App extends Component {
  static contextType = CoreStateContext
  constructor (props) {
    super(props)
    this.state = {
      networkStatus: '',
      idbInitialised: false
    }
  }

  componentDidMount() {
    idbService.startDb(this.handleIndexDbInit)
    this.addNetworkListeners()
    this.handleNetworkChange(null, true)
  }

  handleIndexDbInit = () => {
    this.setState({
      idbInitialised: true
    })
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.onLoad)
    window.removeEventListener("online", this.handleNetworkChange)
    window.removeEventListener("offline", this.handleNetworkChange)
  }

  addNetworkListeners = () => {
    window.addEventListener("load", this.onLoad)
  }

  onLoad = () => {
    window.addEventListener("online", this.handleNetworkChange)
    window.addEventListener("offline", this.handleNetworkChange)
  }

  handleNetworkChange = (event, isinit) => {
    this.setState({
      networkStatus: navigator.onLine ? (!isinit ? 'online' : '') : 'offline'
    }, () => {
      if (this.state.networkStatus === 'online') {
        setTimeout(() => {
          this.setState({
            networkStatus: ''
          })
        }, 3000)
      }
    })
  }

  getNetworkBadge = () => {
    return (
      <>
        { this.state.networkStatus === 'online' &&
          <div className="online-block">
            you are back online
          </div>
        }
        { this.state.networkStatus === 'offline' &&
          <div className="offline-block">
            you are offline
          </div>
        }
      </>
    )
  }

  logDb = () => {
    idbService.addData({
      a: 'b',
      b: 'c',
      d: (new Date()).getTime()
    }, 'endpoint', '/dummy-endpoint')
  }

  getData = () => {
    idbService.getData()
  }

  deleteFromCommonModal = (modalId) => {
    this.context.dispatchCoreAction(deleteFromCommonModal({modalId}))
  }

  getCoreModalList = (coreModalList) => {
    if (!coreModalList || Object.keys(coreModalList).length < 1) {
      document.body.style.overflow = 'unset'
      return null
    }
    const content = []
    for (let modalKey in coreModalList) {
      const modalData = coreModalList[modalKey]
      if (modalData.type) {
        const MappingView = MODAL_TYPE_COMPONENT_MAPPING[modalData.type]
        if (MappingView) {
          content.push(
            <ModalComponent
              isOpen={!!modalData}
              handleOnCloseModal={this.deleteFromCommonModal.bind(null, modalKey)}
              {...modalData} key={modalKey}>
              <MappingView {...modalData.modalProps} />
            </ModalComponent>
          )
        }

      }
    }
    return content
  }

  render() {
    const showNav = !this.props.location.pathname.startsWith('/auth')
    const commonState = this.context.commonState || {}
    const coreModalList = commonState.coreModalList
    const additionalProps = {
      schoolData: commonState.schoolData
    }
    return (
      <div className='app-container'>
        { this.state.idbInitialised &&
          <>
            <ToastContainer
              position='top-right'
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick={false}
              rtl={false}
              pauseOnVisibilityChange={false}
              draggable={false}
              pauseOnHover={false}
              closeButton={false}
            />
            { showNav &&
              <NavBarComponent />
            }
            <Switch>
              <Route exact path='/' render={() => <Redirect to='/auth' />} />
              <PublicRoute path='/auth' component={AuthLazyComponent} />
              <PrivateRoute
                path='/schools/:id' component={SchoolsLazyComponent}
                additionalProps={additionalProps}
              />
              <Route path='*' render={() => { return <p>Not found</p> }} />
            </Switch>
            { this.getCoreModalList(coreModalList) }
            { this.getNetworkBadge() }
          </>
        }
      </div>
    )
  }
}

export default withRouter(App)
