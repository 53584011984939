function prepareMessageBar (message, messageType = 'error') {
  if (!message) {
    return null
  }
  return {
    message,
    messageType
  }
}

function getFileUrlFromBuffer (blobData, type) {
  const stringData = Buffer.from(blobData)
  const blob = new window.Blob([stringData], { type })
  const url = URL.createObjectURL(blob)
  return url
}

export { prepareMessageBar, getFileUrlFromBuffer }
