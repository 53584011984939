import React from 'react'
import './_nav-bar.scss'

const NavBarView = (props) => {
  return (
    <div className="navbar">
      <img className='left-action' src={props.schoolLogoUrl ? props.schoolLogoUrl : ''}
        alt={props.schoolLogoUrl ? 'school-icon' : ''}
        onClick={props.handleDeleteModal} />
      {/* <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M1 2C0.45 2 0 1.55 0 1C0 0.45 0.45 0 1 0H17C17.55 0 18 0.45 18 1C18 1.55 17.55 2 17 2H1ZM1 7H10C10.55 7 11 6.55 11 6C11 5.45 10.55 5 10 5H1C0.45 5 0 5.45 0 6C0 6.55 0.45 7 1 7ZM1 12H12C12.55 12 13 11.55 13 11C13 10.45 12.55 10 12 10H1C0.45 10 0 10.45 0 11C0 11.55 0.45 12 1 12Z" fill="white"/>
      </svg> */}
      <div className='text-block'>
        { props.selectedSchool &&
          <>
            <span className='fs-14' onClick={props.onShowSelectSchoolModal}>
              { props.selectedSchool.name }
            </span>
            <span className='fs-12'>Welcome, { props.userDetails.name }</span>
          </>
        }
      </div>
      {/* <span className='notifications-icon'>
        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.89 20 8 20ZM14 14V9C14 5.93 12.36 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C3.63 3.36 2 5.92 2 9V14L0 16V17H16V16L14 14Z" fill="white"/>
        </svg>
      </span> */}
      <span className='right-action' onClick={props.onShowActions}
        id='right-action-block'>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <div className={`right-action-details ${props.areRightDetailsVisible ? 'opened' : ''}`}>
          <span className='fs-12' onClick={props.onLogout}>
            Logout
          </span>
        </div>
      </span>
    </div>
  )
}

export default NavBarView
